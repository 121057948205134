<template>
  <el-dialog
    v-model="show"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-reconciliation-task-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-reconciliation-task-dialog-header__title">
        {{
          props.model === 'edit'
            ? t('title.editReconciliationTask')
            : props.model === 'copy'
              ? t('title.duplicateReconciliationTask')
              : t('title.createReconciliationTask')
        }}
      </h4>
    </template>
    <div v-loading="dialogLoading" class="elv-reconciliation-task-dialog-container">
      <el-form ref="taskFormRef" :model="taskSetForm" label-position="top" :rules="rules" @submit.prevent>
        <el-form-item :label="t('report.taskName')" prop="description">
          <el-input v-model="taskSetForm.name" :placeholder="t('message.pleaseInput')"
        /></el-form-item>
        <el-form-item :label="`Date from (${currentTimeZone})`" prop="chartOfAccountId">
          <div class="elv-reconciliation-task-dialog-content">
            <span class="elv-reconciliation-task-dialog-content-tag">{{ t('report.to') }}</span>
            <el-date-picker
              v-model="taskSetForm.fromDatetimeStr"
              class="elv-reconciliation-task-dialog-content__date-picker"
              type="datetime"
              :clearable="false"
              :prefix-icon="DatetimeIcon"
              :placeholder="t('common.select')"
              :default-time="new Date(2024, 1, 1, 0, 0, 0)"
              format="YYYY/MM/DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              date-format="MMM DD, YYYY"
              time-format="HH:mm"
              popper-class="elv-reconciliation-task-dialog-content__date-picker-popper"
              @change="onChangeStartDate"
            />
            <el-date-picker
              v-model="taskSetForm.toDatetimeStr"
              class="elv-reconciliation-task-dialog-content__date-picker"
              type="datetime"
              :clearable="false"
              :prefix-icon="DatetimeIcon"
              :placeholder="t('common.select')"
              :default-time="new Date(2024, 1, 1, 23, 59, 59, 0)"
              format="YYYY/MM/DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              date-format="MMM DD, YYYY"
              time-format="HH:mm"
              popper-class="elv-reconciliation-task-dialog-content__date-picker-popper"
              :disabled-date="disableEndDate"
              @change="onChangeEndDate"
            />
          </div>
        </el-form-item>
        <el-form-item :label="t('common.dataTypes')" prop="description">
          <div class="elv-reconciliation-task-dialog-content">
            <SvgIcon
              name="horizontal-arrow"
              width="16"
              height="16"
              :fill="'#AAAFB6'"
              class="elv-reconciliation-task-dialog-content-arrow"
            />
            <SingleChoiceSelect
              v-model="taskSetForm.dataSetTypeA"
              class="elv-reconciliation-task-dialog-content__type-select"
              width="270px"
              :options="DataTypeForReconciliation"
              disabled
            />
            <SingleChoiceSelect
              v-model="taskSetForm.dataSetTypeB"
              class="elv-reconciliation-task-dialog-content__type-select"
              width="270px"
              :options="DataTypeForReconciliation"
              disabled
            />
          </div>
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <elv-button
        type="primary"
        :disabled="disabledSave"
        :loading="saveLoading"
        round
        width="100"
        height="44"
        @click="onSaveReconciliationTask"
        >{{ props.model === 'edit' ? t('button.save') : t('button.create') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import timezone from '@/config/timezone'
import { ElMessage } from 'element-plus'
import { isEmpty, trim } from 'lodash-es'
import DatetimeIcon from './DatetimeIcon.vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import { useEntityStore } from '@/stores/modules/entity'
import type { FormInstance, FormRules } from 'element-plus'
import { DataTypeForReconciliation } from '@/config/reconciliation'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'

const props = defineProps({
  model: {
    type: String,
    default: 'add' // 'add' | 'edit' | 'copy'
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const emit = defineEmits(['onResetList'])

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const show = defineModel('show', { type: Boolean, required: true })

const saveLoading = ref(false)
const dialogLoading = ref(false)
const taskFormRef = ref(null as unknown as FormInstance)
const taskSetForm = reactive({
  name: '',
  dataSetTypeA: 'TRANSACTION',
  dataSetTypeB: 'LEDGER',
  dataSetNameA: 'Data set A',
  dataSetNameB: 'Data set B',
  fromDatetimeStr: '' as any,
  toDatetimeStr: '' as any
})

const rules = reactive<FormRules>({
  name: [
    {
      required: true,
      trigger: 'blur'
    }
  ]
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const disabledSave = computed(() => {
  return !trim(taskSetForm.name) || !taskSetForm.fromDatetimeStr || !taskSetForm.toDatetimeStr
})

const currentTimeZone = computed(() => {
  return timezone.find((item) => item.area === entityStore.entityDetail.timezone)?.timezone
})

/**
 * 禁用结束日期的选择范围（距离开始时间超过一年周期）
 */
const disableEndDate = (date: any) => {
  if (!taskSetForm.fromDatetimeStr) return false
  const startDate = new Date(taskSetForm.fromDatetimeStr)
  const maxEndDate = new Date(startDate)
  maxEndDate.setFullYear(maxEndDate.getFullYear() + 1)
  return date < startDate || date > maxEndDate
}

/**
 * 开始日期变化时，设置结束日期的最小可选日期
 * @param date 开始日期
 */
const onChangeStartDate = (date: Date) => {
  taskSetForm.fromDatetimeStr = date
  // 设置结束日期的最小可选日期为开始日期
  if (taskSetForm.toDatetimeStr && taskSetForm.toDatetimeStr < date) {
    taskSetForm.toDatetimeStr = ''
  } else if (taskSetForm.toDatetimeStr) {
    const endDate = new Date(taskSetForm.toDatetimeStr)
    const oneYearLater = new Date(date)
    oneYearLater.setFullYear(oneYearLater.getFullYear() + 1)

    if (endDate > oneYearLater) {
      taskSetForm.toDatetimeStr = oneYearLater
    }
  }
}

/**
 * 结束日期变化时，检查是否超过一年周期
 * @param date 结束日期
 */
const onChangeEndDate = (newEndDate: Date) => {
  if (taskSetForm.fromDatetimeStr) {
    const startDate = new Date(taskSetForm.fromDatetimeStr)
    const oneYearEarlier = new Date(newEndDate)
    oneYearEarlier.setFullYear(oneYearEarlier.getFullYear() - 1)
    if (startDate < oneYearEarlier) {
      taskSetForm.fromDatetimeStr = oneYearEarlier
    }
  }
}

const onCloseDialog = () => {
  taskFormRef.value?.resetFields()
  taskSetForm.name = ''
  taskSetForm.fromDatetimeStr = ''
  taskSetForm.toDatetimeStr = ''
}

const onAddReconciliationTask = async () => {
  try {
    saveLoading.value = true
    await ReconciliationApi.createReconciliationTask(entityId.value, taskSetForm)
    ElMessage.success(t('message.success'))
    emit('onResetList')
    show.value = false
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onEditTask = async () => {
  try {
    saveLoading.value = true
    const data: any = { ...taskSetForm }
    if (data?.dataSetTypeA) {
      delete data.dataSetTypeA
    }
    if (data?.dataSetTypeB) {
      delete data.dataSetTypeB
    }
    if (props.model === 'edit') {
      await ReconciliationApi.updateReconciliationTask(entityId.value, props.currentData?.reconciliationTaskId, data)
    } else {
      if (data?.dataSetNameA) {
        delete data.dataSetNameA
      }
      if (data?.dataSetNameB) {
        delete data.dataSetNameB
      }
      await ReconciliationApi.copyReconciliationTask(entityId.value, props.currentData?.reconciliationTaskId, data)
    }
    ElMessage.success(t('message.success'))
    emit('onResetList')
    show.value = false
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onSaveReconciliationTask = () => {
  if (props.model === 'add') {
    onAddReconciliationTask()
  } else {
    onEditTask()
  }
}

watch(
  [() => show.value, () => props?.currentData],
  async () => {
    if (show.value && props.model !== 'add' && !isEmpty(props.currentData)) {
      if (props.model === 'copy') {
        taskSetForm.name = `${props?.currentData?.name}-copy`
        taskSetForm.fromDatetimeStr = props.currentData?.fromDatetimeStr
        taskSetForm.toDatetimeStr = props.currentData?.toDatetimeStr
      } else {
        taskSetForm.name = props?.currentData?.name ?? ''
        taskSetForm.fromDatetimeStr = props.currentData?.fromDatetimeStr
        taskSetForm.toDatetimeStr = props.currentData?.toDatetimeStr
        taskSetForm.dataSetNameA = props.currentData?.dataSetNameA ?? ''
        taskSetForm.dataSetNameB = props.currentData?.dataSetNameB ?? ''
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-reconciliation-task-dialog {
  width: 620px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-reconciliation-task-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 6px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-reconciliation-task-dialog-container {
      width: 100%;

      .el-form-item__label {
        pointer-events: none;
      }
    }

    .elv-reconciliation-task-dialog-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .elv-reconciliation-task-dialog-content-tag {
        font-size: 13px;
        font-weight: 600;
        color: #636b75;
        width: 16px;
        position: absolute;
        left: 50%;
        transform: translateX(-8px);
        top: -29px;
      }

      .elv-reconciliation-task-dialog-content-arrow {
        position: absolute;
        left: 50%;
        transform: translateX(-8px);
      }

      .elv-reconciliation-task-dialog-content__date-picker {
        width: 278px;
        height: 44px;

        .el-input__wrapper {
          display: flex;
          flex-direction: row-reverse;

          .el-input__icon {
            margin-right: 0px;
          }
        }
      }

      .elv-reconciliation-task-dialog-content__type-select {
        width: 270px;
        height: 44px;
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-reconciliation-task-dialog-content__date-picker-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    // overflow: hidden;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-picker {
    width: fit-content;

    :has(.el-date-picker__time-header) {
      width: 237px;
    }

    :has(.el-month-table),
    :has(.el-year-table) {
      width: 322px;
    }

    .el-picker-panel__content {
      width: 221px;
      margin: 0;
      margin-top: 6px;

      &:has(.el-month-table),
      &:has(.el-year-table) {
        width: fit-content;
      }

      .el-date-table {
        th {
          min-width: 31.57px;
          height: 16px;
          border-bottom: 0px;
          padding: 0px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 11px;
          line-height: 11px;
          color: #aaafb6;
        }

        tr {
          color: #636b75;
        }

        td {
          padding: 0px;
          width: 31.57px;
          height: 27.8px;
          border-radius: 2px;

          &.today .el-date-table-cell__text {
            color: #1e2024;
          }

          &.available:hover {
            border-radius: 2px;
            background: #edf0f3;

            .el-date-table-cell__text {
              color: #1e2024;
              font-weight: 600;
            }
          }

          &.next-month {
            display: none;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          &.prev-month {
            visibility: hidden;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          .el-date-table-cell {
            padding: 0px;
            width: 31.57px;
            height: 27.8px;

            .el-date-table-cell__text {
              width: 31.57px;
              height: 27.8px;
              font-family: 'Barlow';
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0px;
            }
          }

          &.in-range .el-date-table-cell {
            background-color: #fff;
          }

          &.current .el-date-table-cell {
            margin-left: 0px;
            border-radius: 0px;

            .el-date-table-cell__text {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
              box-sizing: border-box;
              border-bottom: 1px solid #1343bf;
            }
          }
        }
      }

      .el-month-table {
        td {
          width: 54px;
        }
      }
    }

    .el-picker-panel__body {
      padding: 8px;

      .el-date-picker__time-header {
        padding: 0px;
        padding-bottom: 8px;

        .el-input {
          height: 28px;

          .el-input__inner {
            color: #0e0f11;
            font-family: 'Barlow';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }
        }
      }

      .el-time-panel {
        left: -75px;
      }
    }

    .el-date-picker__header {
      margin: 0;
      padding: 0px;
    }

    .el-date-picker__header-label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #aaafb6;
      padding: 0;
      position: relative;
      top: 4px;

      &:nth-child(3) {
        font-weight: 700;
        color: #1e2024;
        margin-left: 4px;
      }
    }
  }

  .el-date-range-picker {
    &.has-time {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header,
  .el-date-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}
</style>
